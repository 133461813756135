<template>
  <VTable
    :head-row="headRow"
    :is-checkbox="false"
    url-set-rows="/registers/event"
    sort="created_at"
  />
</template>

<script>
import VTable from '@/components/ui/table/VTable'

export default {
  setup () {
    const headRow = [
      {
        name: 'Дата и время ошибки',
        value: 'created_at',
        filter: true
      },
      {
        name: 'Ошибка',
        value: 'is_success',
        filter: true
      },
      {
        name: 'Событие',
        value: 'event',
        filter: true,
      },
      {
        name: 'Расшифровка сообщения',
        value: 'description',
        filter: true
      }
    ]
    return {
      headRow,
    }
  },
  components: {
    VTable
  }
}
</script>
